import { Outlet, Link } from "react-router-dom";
import { Navbar } from './Components';


function Layout() {
  return (
    <>
      <Navbar />

      <Outlet />
    </>
  )
}

export default Layout;