import Hello from "../Hello";

function Home() {
  return (
    <>
      <h1>Home</h1>
      <Hello />
    </>
  )
}

export default Home;